<template>
  <div class="row no-gutters resetmail">
    <div class="col-10 offset-1">
      <div class="row no-gutters">
        <div class="col-lg-5 order-1">
          <div class="a-margin-xl row">
            <div class="col-12 m-card">
              <h1 class="o-heading o-heading--xl mt-3">
                {{ $t("resetMail.box.message") }}
              </h1>
              <p>
                {{ $t("resetMail.box.message_1") }}
              </p>

              <p class="mt-2 mb-3">
                <strong>{{ $t("resetMail.box.message_2") }}</strong>
              </p>
            </div>
            <div class="col-12 pt-3 d-none d-lg-block">


            </div>
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1 order-2">
          <app-reset-mail-step></app-reset-mail-step>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppResetMailStep from "../components/organisms/AppResetMailStep.vue";

export default {
  components: { AppResetMailStep },
  name: "registrazione",

  data() {
    return {
      message: "",

    };
  },

  methods: {
    annulla:function()
    {

    }
  },
};
</script>
