<template>

  <div class="o-registration-collapse">

    <div class="accordion" role="tablist">
      <div block :class="funtionCollapseStates(0)">
        <div class="m-circle-icon m-circle-icon--xs" v-bind:class="{ 'o-registration-collapse__hide': !showCheck[0] }">
          <span class="icon-check"></span>
        </div>
        {{ $t("resetMail.step1.header") }}
        <button class="icon-pen o-registration-collapse__btn--edit"
          v-bind:class="{ 'o-registration-collapse__hide': !showPen[0] }" v-b-toggle="['accordion-anagrafici']"
          :disabled="showPen[0] !== true "></button>
      </div>
      <b-collapse id="accordion-msptel" visible accordion="my-msptel" role="tabpanel"
        class="o-registration-collapse__form" v-model="collapseStates[0]">
        <b-form-group id="msp" :label="this.$t('resetMail.step1.msp')" label-for="msp-input" class="m-input">
          <b-form-input id="msp-input" v-model="resetMail.msp" type="text"
            :placeholder="this.$t('resetMail.step1.msp_place')" required></b-form-input>
          <b-form-invalid-feedback>
            {{ $t("step.invalid_nome") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="cell" :label="this.$t('resetMail.step1.cell')" label-for="cell-input" class="m-input">
          <b-form-input id="cell-input" v-model="resetMail.cell" type="text"
            :placeholder="this.$t('resetMail.step1.cell_place')" required></b-form-input>
          <b-form-invalid-feedback>
            {{ $t("step.invalid_cognome") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="row no-gutters mt-3">
          <div class="col-12 offset-0">
            <b-alert  variant="danger" id="errore_step_1" v-model="errStep1" >{{ $t('resetMail.step1.errors.'+this.errStep1_message) }}</b-alert>
          </div>
        </div>
        <div class="o-registration-collapse__action">
          <b-button @click="resetMailInit()" :disabled="showCheck[0] !== true || step!=0" variant="primary" size="md">{{ $t("registration.continua") }}
          </b-button>
        </div>
      </b-collapse>

      <div block :class="funtionCollapseStates(1)">
        <div class="m-circle-icon m-circle-icon--xs" v-bind:class="{ 'o-registration-collapse__hide': !showCheck[1] }">
          <span class="icon-check"></span>
        </div>
        {{ $t("resetMail.step2.header") }}
        <button class="icon-pen o-registration-collapse__btn--edit"
          v-bind:class="{ 'o-registration-collapse__hide': !showPen[1] }"
          v-b-toggle="['accordion-sblocco']"></button>
      </div>
      <b-collapse id="accordion-sblocco" accordion="my-sblocco" role="tabpanel"
        class="o-registration-collapse__form" v-model="collapseStates[1]">
        <b-form-group id="code" :label="$t('resetMail.step2.code')" label-for="code-input" class="m-input">
          <b-form-input id="code-input" v-model="resetMail.code" type="text"
            :placeholder="this.$t('resetMail.step2.code_place')" ></b-form-input>
        </b-form-group>

        <div class="row no-gutters mt-3">
          <div class="col-12 offset-0">
            <b-alert  variant="danger" id="errore_step_2" v-model="errStep2" >{{ $t('resetMail.step2.errors.'+this.errStep2_message) }}</b-alert>
          </div>
        </div>

        <div class="o-registration-collapse__action">
          <b-button @click="resetMailVerify()" :disabled="showCheck[1] !== true || step!=1" variant="primary" size="md">{{ $t("registration.continua") }}
          </b-button>
          <b-button
              variant="outline-primary"
              class="text-uppercase  ml-xl-2"
              @click="annulla()"
          >{{ $t("registration.noaction") }}</b-button>
        </div>
      </b-collapse>

      <div block :class="funtionCollapseStates(2)">
        <div class="m-circle-icon m-circle-icon--xs" v-bind:class="{ 'o-registration-collapse__hide': !showCheck[2] }">
          <span class="icon-check"></span>
        </div>
        {{ $t("resetMail.step3.header") }}
        <button class="icon-pen o-registration-collapse__btn--edit"
          v-bind:class="{ 'o-registration-collapse__hide': !showPen[2] }"
          v-b-toggle="['accordion-inserimento']"></button>
      </div>
      <b-collapse id="accordion-inserimento" accordion="my-inserimento" role="tabpanel"
        class="o-registration-collapse__form" v-model="collapseStates[2]">

        <b-form-group id="email" :label="$t('resetMail.step3.email')" label-for="email-input" class="m-input">
          <b-form-input id="email-input" v-model="resetMail.email" type="text"
                        :placeholder="this.$t('resetMail.step3.email_place')" ></b-form-input>
        </b-form-group>
        <b-form-group id="email_verify" :label="$t('resetMail.step3.email_verify')" label-for="email_verify-input" class="m-input">
          <b-form-input id="email_verify-input" v-model="resetMail.email_verify" type="text"
                        :placeholder="this.$t('resetMail.step3.email_verify_place')" ></b-form-input>
        </b-form-group>
        <div class="o-registration-collapse__action">

          <b-button variant="primary" size="md" @click="resetMailUpdate()"
            :disabled="showCheck[2] !== true || step!=2">{{ $t("registration.continua") }}
          </b-button>
          <b-button
              variant="outline-primary"
              class="text-uppercase  ml-xl-2"
              @click="annulla()"
          >{{ $t("registration.noaction") }}</b-button>
        </div>
      </b-collapse>


      <div block :class="funtionCollapseStates(3)">
        <div class="" v-bind:class="{
          'o-registration-collapse__hide': showCheck[3],
        }">
        &nbsp;
        </div>
        {{ $t("resetMail.step4.header") }}
        <button class="icon-pen o-registration-collapse__btn--edit"
          v-bind:class="{ 'o-registration-collapse__hide': !showPen[3] }" v-b-toggle="['accordion-resoconto']"></button>
      </div>
      <b-collapse id="accordion-resoconto" accordion="my-resoconto" role="tabpanel" class="o-registration-collapse__form"
        v-model="collapseStates[3]">

<p>
  {{$t('resetMail.step4.message')}}
</p>
        <p>
          {{$t('resetMail.step4.message2')}}<router-link to="/login"><strong><u>{{$t('resetMail.step4.link_text')}}</u></strong></router-link>
        </p>
      </b-collapse>
   </div>
  </div>
</template>

<script>

//import VueSimpleScrollbar from "vue-simple-scrollbar";
//import Places from "vue-places";
import apiAxios from "@/libs/apiAxios";

export default {
  name: "AppResetMailStep",
  components: {
    //    VueSimpleScrollbar,
    //    Places,
  },
  //props: ["registration"],
  data() {
    return {
      step:0,
      errStep1:false,
      errStep1_message:'',
      errStep2:false,
      errStep2_message:'',
      errStep3:false,
      errStep3_message:'',
      resetMail:{
        msp:null,
        cell:null,
        otp:null,
        code:null,
        hash:null,
        email:null,
        email_verify:null
      },

      err: 0,

      showPen: [false, false, false, false],
      showCheck: [false, false, false, false],
      disableButton: [false, true, true, true],
      collapseStates: [true, false, false, false],

    };
  },
  computed: {
    isApp() {
      return this.$route.query.app
    }
  },
  created() {

  },

  watch: {
    resetMail: {
      handler() {
        this.checkRegistration();
      },
      deep: true,
    },
  },

  methods: {

    annulla()
    {
      this.step=0;
      this.resetMail.email=null;
      this.resetMail.email_verify=null;
      this.resetMail.hash=null;
      this.resetMail.code=null;
      this.resetMail.otp=null;
      this.resetMail.msp=null;
      this.resetMail.cell=null;
      this.toggleCollapse(0);
    },

    toggleCollapse(idShowing)
    {
      for (let i=0;i<this.collapseStates.length;i++)
      {
        if (i==idShowing)
        {
          this.funtionCollapseStates(i);
        }
       this.collapseStates[i]=(i==idShowing);
      }
    },

    resetMailInit()
    {

      this.errStep1=false;
      const data={
        msp:this.resetMail.msp,
        cell:this.resetMail.cell
      };
      apiAxios.post("account/resetMail",data).then(result=>
      {
        const data=result.data.ResetMailResponse;
        if (data.success)
        {
          this.resetMail.otp=data.otp;
          this.step=1;
          this.toggleCollapse(1);
        }
        else {
          this.errStep1_message=data.error;
          this.errStep1=true;
        }
      }).catch(errore=>{
        console.log(errore);
      });
    },
    resetMailVerify()
    {

      this.errStep2=false;
      const data={
        otp:this.resetMail.otp,
        code:this.resetMail.code
      };
      apiAxios.post("account/resetMail/verify",data).then(result=>
      {
        const data=result.data.ResetMailVerifyResponse;
        if (data.success)
        {

          this.step=2;
          this.resetMail.hash=data.hash;
          this.toggleCollapse(2);
        }
        else {
          this.errStep2_message=data.error;
          this.errStep2=true;
        }
      }).catch(errore=>{
        console.log(errore);
      });
    },
  resetMailUpdate()
  {

    this.errStep3=false;


    if (this.resetMail.email==null || this.resetMail.email_verify==null)
    {
      return;
    }

    let email_v=this.resetMail.email.trim();
    let email_verify=this.resetMail.email_verify.trim();

    if (email_v!=email_verify)
    {
      return;
    }

    const data={
      otp:this.resetMail.otp,
      hash:this.resetMail.hash,
      email:email_v
    };
    apiAxios.post("account/resetMail/update",data).then(result=>
    {
      console.log(result);
      const data=result.data.ResetMailUpdateResponse;
      if (data.success)
      {

        this.step=3;
        this.toggleCollapse(3);
      }
      else {
        this.errStep3_message=data.error;
        this.errStep3=true;
      }
    }).catch(errore=>{
      console.log(errore);
    });
  },
    isEmail(email) {
      const regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
      if (email != null) {
        if (regExp.test(email) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    funtionCollapseStates($id) {
      if (this.collapseStates[$id] == false) {
        if (this.showCheck[$id] == true) {
          return "o-registration-collapse__btn o-registration-collapse__btn--close o-registration-collapse__btn--cheked";
        } else {
          return "o-registration-collapse__btn o-registration-collapse__btn--close";
        }
      } else {
        return "o-registration-collapse__btn o-registration-collapse__btn--open";
      }
    },

    checkRegistration() {
      //Anagrafica

      let check1 = false;

      if (this.resetMail.msp!=null && this.resetMail.cell!=null) {
        check1 = (
            this.resetMail.msp != "" && this.resetMail.msp.length > 5 &&
            this.resetMail.cell.length>5 && (this.isPhone(this.resetMail.cell) === null));
      }


      this.showCheck[0] = check1;
      let check2=false;

      if (check1 && this.resetMail.code!=null)
      {
        check2=this.resetMail.code!= "" && this.resetMail.code.length>5
      }


      let check3=false;
      if (check2 && this.resetMail.email_verify!=null && this.resetMail.email!=null)
      {
        console.log(this.resetMail);
        check3=this.resetMail.email.length>0 &&this.isEmail(this.resetMail.email)==null && (this.resetMail.email.trim()===this.resetMail.email_verify.trim());
      }



      this.showPen[0] = check1
      this.showCheck[1] = check2;
      this.showPen[1] = check2;
      this.showCheck[2] = check3;
      this.showPen[2] = check3;
      this.showCheck[3] =false;
      this.showPen[3] = false;

    },

    isPhone(phone) {
      // const regExp = /^(([+]|00)39)?((3[1-6][0-9]))(\d{7})$/g;
      const regExp = /^(\+|\d)[0-9]{7,16}$/g;
      if (phone != null && phone != "") {
        if (regExp.test(phone) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  },
};
</script>
